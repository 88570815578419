const moduleRoute = require("./module.js").route;

export default [
  {
    type: "divider",
    name: "Notifications Management"
  },
  {
    type: "route",
    name: "Notifications",
    description: "Manage Notifications",
    icon: "mdi-account-tie",
    url: moduleRoute + "/notifications",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  }
];
