const homeLibrary = [
  {
    type: "route",
    name: "Home",
    description: "Navigate to homepage (this page)",
    icon: "mdi-home",
    url: "/",
    meta: {
      isHome: true
    },
    location: {
      menu: true,
      toolbar: false,
      home: false
    }
  }
];

const authLibrary = [
  {
    type: "sso",
    name: "Sign In",
    description: "Log in using your username and password",
    icon: "mdi-login",
    url: undefined,
    meta: {
      public: true,
      onlyNotAuth: true,
      isLogin: true
    },
    location: {
      menu: false,
      toolbar: false,
      home: true
    }
  },
  {
    type: "sso",
    name: "Manage Account",
    description: "Manage account One-Time-Password and other settings",
    icon: "mdi-account-settings",
    url: undefined,
    meta: {
      isManage: true,
      color: "red",
      light: false
    },
    location: {
      menu: true,
      toolbar: true,
      home: true
    }
  },
  {
    type: "sso",
    name: "Sign Out",
    description: "Logout from all sessions in this browser window",
    icon: "mdi-logout",
    url: undefined,
    meta: {
      isLogout: true,
      color: "red",
      light: false
    },
    location: {
      menu: true,
      toolbar: true,
      home: true
    }
  }
];

// Modules Library
const modulesLibrary = [];
const modules = require("@/modules/modules.js");

if (modules && Array.isArray(modules) && modules.length > 0) {
  for (let index = 0; index < modules.length; index++) {
    const moduleInstance = modules[index];
    if (moduleInstance && moduleInstance.enabled) {
      if (moduleInstance.name && moduleInstance.route && moduleInstance.menu) {
        const wrapInFolder = moduleInstance.menu.folder;
        if (wrapInFolder && !moduleInstance.menu.name && !moduleInstance.menu.icon) {
          continue;
        }
        const moduleLibrary = require("@/modules/" + moduleInstance.name + "/library.js").default;
        if (moduleLibrary && Array.isArray(moduleLibrary) && moduleLibrary.length > 0) {
          if (wrapInFolder) {
            modulesLibrary.push({
              type: "folder",
              name: moduleInstance.menu.name,
              icon: moduleInstance.menu.icon,
              children: [...moduleLibrary],
              model: moduleInstance.menu.expanded || false
            });
          } else {
            modulesLibrary.push(...moduleLibrary);
          }
        }
      }
    }
  }
}

const configLibrary = [...homeLibrary, ...modulesLibrary, ...authLibrary];

module.exports = configLibrary;
