const moduleRoute = require("./module.js").route;

export default [
  {
    type: "divider",
    name: "EORI Management"
  },
  {
    type: "route",
    name: "Economic Operators",
    description: "Manage Economic Operators",
    icon: "mdi-account-tie",
    url: moduleRoute + "/operators",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  }
];
