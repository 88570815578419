const moduleRoute = require("./module.js").route;

export default [
  {
    type: "divider",
    name: "Cargo Management"
  },
  {
    type: "route",
    name: "Cargo Voyages",
    description: "Manage Cargo Voyages",
    icon: "mdi-transit-connection-variant",
    url: moduleRoute + "/voyages",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Port Calls",
    description: "Manage Port Calls",
    icon: "mdi-pier-crane",
    url: moduleRoute + "/portcalls",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Cargo declarations",
    description: "Manage Cargo Declarations",
    icon: "mdi-file-document",
    url: moduleRoute + "/declarations",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Consignments",
    description: "Manage Consignments",
    icon: "mdi-package",
    url: moduleRoute + "/consignments",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Transport Equipments",
    description: "Manage Transport Equipments",
    icon: "mdi-package-variant-closed",
    url: moduleRoute + "/equipments",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Vessels",
    description: "Manage Vessels",
    icon: "mdi-ferry",
    url: moduleRoute + "/vessels",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  },
  {
    type: "route",
    name: "Offices",
    description: "Manage Offices",
    icon: "mdi-office-building",
    url: moduleRoute + "/offices",
    location: {
      menu: true,
      toolbar: false,
      home: true
    }
  }
];
