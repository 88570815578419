const moduleRoute = require("./module.js").route;

export default [
  {
    type: "folder",
    name: "User Management",
    icon: undefined,
    children: [
      {
        type: "divider",
        name: "User Management"
      },
      {
        type: "route",
        name: "User Accounts",
        description: "Manage user accounts and their permissions",
        icon: "mdi-account-multiple",
        url: moduleRoute + "/accounts",
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      },
      {
        type: "route",
        name: "User Groups",
        description: "Manage user groups and their permissions",
        icon: "mdi-account-group",
        url: moduleRoute + "/groups",
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      }
    ]
  },
  {
    type: "folder",
    name: "Access & Security",
    icon: undefined,
    children: [
      {
        type: "divider",
        name: "Access & Security"
      },
      {
        type: "route",
        name: "Password Policies",
        description: "Manage password policies at system and group levels",
        icon: "mdi-lock",
        url: moduleRoute + "/policies",
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      },
      {
        type: "route",
        name: "Access Units",
        description: "Manage access units (group of access roles)",
        icon: "mdi-decagram-outline",
        url: moduleRoute + "/units",
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      },
      {
        type: "route",
        name: "Access Roles",
        description: "Manage access roles (permissions)",
        icon: "mdi-decagram",
        url: moduleRoute + "/roles",
        location: {
          menu: true,
          toolbar: false,
          home: true
        }
      }
    ]
  },
  {
    type: "divider",
    name: "My Account"
  },
  {
    type: "route",
    name: "My Profile",
    description: "Manage and modify your profile and password",
    icon: "mdi-account",
    url: moduleRoute + "/profile",
    location: {
      menu: true,
      toolbar: true,
      home: true
    }
  },
  {
    type: "route",
    name: "Change Password",
    description: "Change your password",
    icon: "mdi-lock",
    url: moduleRoute + "/profile/password",
    location: {
      menu: true,
      toolbar: true,
      home: false
    }
  },
  {
    type: "route",
    name: "User Registration",
    description:
      "Create an account using your e-mail address and mobile number, and link it with your Tax Identification Number",
    icon: "mdi-account-plus",
    url: moduleRoute + "/register",
    location: {
      menu: false,
      toolbar: false,
      home: true
    }
  }
];
